import React, { Component } from "react";
import '../css/react-responsive-modal.css';
import { connect } from 'react-redux';
import ModalVideo from 'react-modal-video';

class Player extends Component {
    render() {
        return (
            <ModalVideo
                channel="youtube"
                isOpen={this.props.myprops.open}
                videoId="FU9jjDa2EL0"
                onClose={() => this.props.changeStatus()}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        myprops: state.popup
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeStatus: () => {
            dispatch({ type: 'hide' })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Player);
