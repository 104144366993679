
import videosStruct from '../../assets/videosStruct.json';

const initialState = {
  nosVideos: videosStruct
};

const nosVideos = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
}

export default nosVideos;
