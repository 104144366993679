import { connect } from 'react-redux';
import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';

class Maps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            center: {
                lat: 15.880480004225806,
                lng: -61.313555875456885,
            },
            zoom: 19
        };
    }

    componentMap = (text) => {
        return ( <div>{text}</div> )
    }

    render() {
        const maps = this.props.gites.gites[this.props.initial].maps
        const id = this.props.gites.gites[this.props.initial].id
        const nom = this.props.gites.gites[this.props.initial].nom
        const AnyReactComponent = ({ text }) => <div>{text}</div>;
        return (
            // Important! Always set the container height explicitly
            <div style={{ height: '40vh', width: '100%', zIndex: 2}} className="overlay" ref="childDiv">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyALHroF9oonL-U4T5sh-KKQtdSCLxMoiLA" }}
                    defaultCenter={maps.center}
                    defaultZoom={maps.zoom}
                    key={"maps_" + id}
                    draggable={true}
                    options={{
                        mapTypeId: "satellite",
                        panControl: true,
                        mapTypeControl: true,
                        styles: [{
                            featureType: "poi.business",
                            elementType: "labels",
                            stylers: [{
                                visibility: "on"
                            }]
                        }]
                    }}
                >

                    <Marker
                        key={"marker_" + id}
                        text={"Ce est un test"}
                        lat={maps.center.lat}
                        lng={maps.center.lng}
                    />

                    <AnyReactComponent
                        lat={maps.center.lat}
                        lng={maps.center.lng}
                        text={nom}
                        key={"maps_"+nom}
                    />
                </GoogleMapReact>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        popup: state.popup,
        home: state.home,
        gites: state.gites,
    }
}

export default connect(mapStateToProps)(Maps);
