import * as React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

const actions = {
  title: 'Des gîtes à partir de 50 € à Grand-Bourg de Marie-Galante (Guadeloupe).',
  url: 'https://www.vacansosoleil.com'
};

export default function FloatingActionButtons() {

  return (
    <>
      <div className="fab-wrapper z-3 p-5 rounded-3" style={{ zIndex: 2000}}>
        <input id="fabCheckbox" type="checkbox" className="fab-checkbox" />
        <label className="fab" htmlFor="fabCheckbox">
          <span className="fab-dots fab-dots-1"></span>
          <span className="fab-dots fab-dots-2"></span>
          <span className="fab-dots fab-dots-3"></span>
        </label>
        <div className="fab-wheel">
          <EmailShareButton subject={actions.title} url={window.location}><EmailIcon className="fab-action fab-action-1" size={36} round={true}/></EmailShareButton>
          <TwitterShareButton title={actions.title} url={window.location}><XIcon className="fab-action fab-action-2" size={36} round={true}></XIcon></TwitterShareButton>
          <FacebookShareButton subject={actions.title} url={window.location}><FacebookIcon className="fab-action fab-action-3" size={36} round={true}/></FacebookShareButton>
          <WhatsappShareButton title={actions.title} url={window.location}><WhatsappIcon className="fab-action fab-action-4" size={36} round={true}/></WhatsappShareButton>
        </div>
      </div>
    </>
  )
}
