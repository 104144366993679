import { DateRangePicker, CustomProvider } from 'rsuite';
import { connect } from 'react-redux';
import frFR from 'rsuite/locales/fr_FR';
import store from '../app/store';
import { useState } from 'react';
import * as moment from 'moment'

function RangePicker(props) {
  const [className, setClassName] = useState('form-control bg-light is-invalid')
  const { beforeToday } = DateRangePicker;

  function handerSetNbNuit(e) {
    if (e === null) {
      return
    }
    let tmp = e[1] - e[0];
    let debut = moment(new Date(e[0])).format('DD/MM/YYYY')
    let fin = moment(new Date(e[1])).format('DD/MM/YYYY')
    if (tmp > 0) {
      setClassName('form-control bg-light is-valid');
      props.setSharedNbNuit('setNbNuit', parseInt(Math.floor(tmp / 86400000).toFixed(0)), debut, fin)
      props.calculTarif(store.getState().gites.gites[props.initial].tarif)
    } else {
      setClassName('form-control bg-light is-invalid');
    }
  }

  return (
    <>
      <button />
      <CustomProvider locale={frFR}>
        <DateRangePicker key="tete"
          showOneCalendar
          size='lg'
          shouldDisableDate={beforeToday()}
          cleanable={false}
          placeholder="Date du séjour"
          onClean={(range) => handerSetNbNuit(range)}
          editable={false}
          preventOverflow={true}
          locale={frFR}
          className={className}
          ranges={[]}
          style={{ height: '55px' }}
          format='dd-MM-yyyy'
          onChange={(range) => handerSetNbNuit(range)}
        />
      </CustomProvider>
    </>
  )
}

const mapStateToProps = state => {
  return {
    gites: state.gites,
    contact: state.contact,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSharedNbNuit: (type, nbNuit, debut, fin) => {
      dispatch({ type: type, payload: nbNuit, debut: debut, fin: fin })
    },
    calculTarif: (giteTarif) => {
      dispatch({ type: 'calcul', tarif: giteTarif })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RangePicker);
