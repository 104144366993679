import { Component } from 'react';
import AwesomeSlider from 'react-awesome-slider';
// import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import GalleryConfig from '../assets/gallery_gites.json';

// const AutoplaySlider = withAutoplay(AwesomeSlider);

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: GalleryConfig.jp,
        }
    }

    render() {
        const images = this.props.images
        return <AwesomeSlider
            name="images"
            animation="cubeAnimation"
            tranisionDelay={300}
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={6000}
        >
            <div data-src="/img/gites/be/std_3.jpg" />
            <div>
                <picture>
                    <source srcset="/img/contact.avif" type="image/avif" />
                    <source srcset="/img/contact.webp" type="image/webp" />
                    <img className="w-100" alt="Contactez-nous" src="/img/contact.jpg" />
                </picture>
            </div>
            <div>
                <img src="/img/gites/be/std_6.jpg" />
            </div>
            <div data-src="/img/gites/be/std_5.jpg" />
        </AwesomeSlider>;
    }
}

export default Slider;