import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function Tarifs(props) {
    const gites = props.gites.gites
    let nbNuit = props.nombreNuit - 2

    return (
        <>
            {Object.entries(gites).map(([initial, gite]) => {
                return (
                    <div key={"tarif_" + gite.initial} id={"tarif_" + gite.initial} className='p-4 table-responsive pb-4 border-inner'>
                        <Link to={"/hebergement-a-marie-galante/" + gite.nom.replace(/\W/g, '-').toLowerCase()}> <h2>{gite.nom}<p className='fs-5'>Cliquez ici pour voir la description.</p></h2></Link>
                        <table className="table table-dark table-hover table-striped text-center pb-4 m-0">
                            <thead>
                                <tr>
                                    <th scope="col">Nuit(s)</th>
                                    {[...Array(gite.maxclient)].map((e, i) => {
                                        if ((i + 1) > 3) {
                                            let jusqua = i + 1 === 4 ? "1 à " : ""
                                            return <th key={"tarif" + gite.initial + i} scope="col">{jusqua} {i + 1} p</th>
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    [...Array(5)].map((e, nn) => {
                                        let range = nbNuit
                                        let nombreNuit = nn + range
                                        const clName = nombreNuit == props.nombreNuit ? "rounded-pill table-success font-weight-bold" : ""
                                        if (nombreNuit > 0) {
                                            return <tr>
                                                <th scope="row" >{nombreNuit}</th>
                                                {[...Array(gite.maxclient)].map((e, nc) => {
                                                    range++
                                                    if (nc + 1 > 3) {
                                                        let suppClient = nc + 1 - 4 > 0 ? nc + 1 - 4 : 0
                                                        return <td className={clName} key={"tarif" + gite.initial + nc + " " + nn + range}>{(nombreNuit * gite.tarif) + (nombreNuit * suppClient * 10)}</td>
                                                    }
                                                })}
                                            </tr>
                                        }
                                    }
                                    )}
                            </tbody>
                        </table>
                    </div>
                )
            })}
        </>
    );
}

const mapStateToProps = state => {
    return {
        gites: state.gites,
    }
}

export default connect(mapStateToProps)(Tarifs);
