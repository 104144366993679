import { Component } from 'react';
import { connect } from 'react-redux';
import 'react-modal-video/css/modal-video.min.css';
import version from '../version.txt';

class Footer extends Component {
  componentDidMount = () => {
    this.handleClick();
  };
  handleClick = () => {
    fetch(version)
      .then((r) => r.text())
      .then((version) => {
        this.setState({ version });
      });
  };

  render() {
    return (
      <div className="container-fluid py-2 pb-2" style={{ background: '#111111' }}>
        <div className="container text-center">
          <p className="mb-0">&copy; <a className="text-white border-bottom" href="#">Vacansosoleil</a>. {this.state?.version}. All Rights Reserved.
            <a className="text-white border-bottom" href="https://htmlcodex.com">HTML Codex</a>
          </p>
          Distributed By: <a className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</a>. &nbsp;
          Icons By: <a className="border-bottom" href="https://www.flaticon.com/fr/" target="_blank">Flaticon</a>.
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    popup: state.popup,
    home: state.home,
    gites: state.gites
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeStatus: () => {
      dispatch({ type: 'view' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
