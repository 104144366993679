import ImageGallery from "react-image-gallery";
import GalleryConfig from '../assets/gallery.json';
import { Component } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import '../css/custom_image-gallery.css';

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: GalleryConfig.jp,
        }
    }

    render() {
        const images = this.props.images
        return <ImageGallery items={images}
            autoPlay={true} slideDuration={500}
            useTranslate3D={true}
            showBullets={true}
            showThumbnails={false}
            lazyLoad={true}
            slideInterval={4500}
        />;
    }
}

export default Gallery;