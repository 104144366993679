import { Component } from 'react';
import { connect } from 'react-redux';
import Tarifs from './Tarifs';
import { Helmet } from "react-helmet";


class PageTarifs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nombreNuit: 5,
            gites: this.props.gites.gites,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setNbNuit = (e) => {
        this.setState({ nombreNuit: +e.target.value })
    }

    nombreDeNuit() {
        return (
            Array.from(Array(30), (e, i) => {
                return <option key={"nuits_select_" + i} value={i + 1} >{i + 1}</option>
            })
        )
    }

    render() {
        const nbNuit = this.state.nombreNuit
        return (
            <div className='bg-img'>
                <Helmet>
                    <title>Les tarifs de nos gîtes</title>
                </Helmet>
                <div className="pt-4">
                    <div id="tarifs" className="section-title position-relative text-center mx-auto pt-2 p-4 shadow mb-2 bg-body rounded py-2 " style={{ maxWidth: '600px' }}>
                        <h1 className="text-primary font-secondary">Tarifs en Euro</h1>
                    </div>
                    <div className="container-flex text-secondary py-2" style={{ marginTop: '20px' }}>
                        <div className="container bg-primary" >
                            <div><p className='fs-2'>Sélectionner le nombre de nuits:</p>

                                <select defaultValue={this.state.nombreNuit} className="form-select form-select-lg mb-3" style={{ maxWidth: '120px' }} onChange={(e) => this.setNbNuit(e)}>{this.nombreDeNuit()}</select>
                            </div>
                            <div className="row gx-4 pb-4 bg-primary align-items-md-center d-flex justify-content-evenly">
                                <Tarifs nombreNuit={nbNuit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        gites: state.gites,
    }
}

export default connect(mapStateToProps)(PageTarifs);
