const initialState = {
  open: false,
  mailValidatorOpen: false
};

const accueilPopup = (state = initialState, action) => {
  switch(action.type) {
    case 'view':
      return {
      ...state,
      open: true
      };
    case 'hide':
      return {
        ...state,
        open: false
        };
    case 'mailValidator':
      return {
        ...state,
        mailValidatorOpen: action.payload
        };
    default:
      return state;
  }
};

export default accueilPopup;
