const initialState = [
  `Découverte de Marie-Galante, un trésor caché.`,
  `Située dans les Antilles françaises, l'île de Marie-Galante offre une expérience unique et authentique pour les voyageurs en quête d'une destination hors des sentiers battus. La charmante ville de Grand-Bourg est le cœur de cette île paradisiaque, où vous trouverez des gîtes confortables pour profiter pleinement de votre séjour.`,
  `Pourquoi choisir Grand-Bourg pour votre séjour ?`,
  `Les avantages de séjourner à Grand-Bourg sont nombreux. En tant que principal centre urbain de Marie-Galante, cette destination dispose d'un large éventail de services et d'équipements pour rendre votre séjour agréable et sans souci. De plus, la ville est entourée de plages immaculées, de paysages naturels époustouflants et d'un riche patrimoine culturel.`,
  `Afin de satisfaire tous les  budgets, nous vous proposons différents types de gîtes pouvant héberger de 1 à 8 personnes.`,
  `Toutes nos locations de gîtes sont climatisées et proches de toutes commodités.`
];

const homePages = (state = initialState, action) => {
  return state;
}

export default homePages;
