import { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import ShortGites from './ShortGites';
import '../css/react-responsive-modal.css';
import { Helmet } from "react-helmet";

class PageNosGites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: this.props.videos.nosVideos,
      selectedTab: 0,
    }
  }

  nextTab = (idx) => {
    this.setState({ selectedTab: idx })
  }

  render() {
    const videos = this.props.videos.nosVideos
    return (
      <div className='bg-img'>
        <Helmet>
          <title>Nos vidéos à Grand-Bourg de Marie-Galante</title>
        </Helmet>
        <div className="container-fluid pt-4 pb-4" style={{ maxWidth: '1296px' }}>
          <div className="pb-4">
            <div id="video" className="section-title position-relative text-center mx-auto pt-2 p-4 shadow mb-4 bg-body rounded py-2" style={{ maxWidth: '600px' }}>
              <h1 className="text-primary font-secondary">Nos videos</h1>
              <h2 className="display-4 text-uppercase">nos videos</h2>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center bg-dark text-uppercase border-inner p-4 mb-4">
                {videos.map((vi, idx) => {
                  return (
                    <li className="nav-item" key={"video-key-li" + idx}>
                      <a type="button" onClick={() => this.nextTab(idx)} className={"nav-link text-white " + vi.active} data-bs-toggle="pill" href={"#tab-" + idx}>{vi.tab_title}</a>
                    </li>
                  )
                })}
              </ul>
              <div className="row tab-content">
                {this.state.videos.map((vi, index) => {
                  const display = index === this.state.selectedTab ? "" : "d-none"
                  return (
                    vi.sous_tab.map(viconfig => {
                      return (
                        <div id={"tab-" + this.state.selectedTab} key={"video-key-" + this.state.selectedTab} className={"tab-pane fade show p-0 align-items-md-center justify-content-center active" + display}>
                          <div className="row">
                            <div className="shadow">
                              <div className="video-wrapper">
                                <ReactPlayer
                                  width='100%'
                                  height='30vh'
                                  controls={true}
                                  url={viconfig.video_url} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }))
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <ShortGites />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    videos: state.videos,
  }
}

export default connect(mapStateToProps)(PageNosGites);
