import contactStruct from '../../assets/contactStruct.json';

const initialState = contactStruct
// {
//   "client_mail": "",
//   "client_nom": "",
//   "client_prenom": "",
//   "client_telephone": "",
//   "contact_preference": "",
//   "gite_selected": "",
//   "gite_url": "",
//   "gite_localisation_url": "",
//   "mail_message": "",
//   "mail_sujet": "",
//   "montant_sejour": 0,
//   "montant_arrhes": 0,
//   "nombre_nuits": 0,
//   "nombre_personnes": 0,
//   "sejour_debut": "",
//   "sejour_fin": "",
//   "lieu": ""
// }

const contactForm = (state = initialState, action) => {
  switch (action.type) {
    case 'setNom':
      return {
        ...state,
        client_nom: action.payload
      };
    case 'setPrenom':
      return {
        ...state,
        client_prenom: action.payload
      };
    case 'setNbNuit':
      return {
        ...state,
        nombre_nuits: action.payload,
        sejour_debut: action.debut,
        sejour_fin: action.fin,
      };
    case 'setMail':
      return {
        ...state,
        client_mail: action.payload
      };
    case 'setContactMode':
      return {
        ...state,
        contact_preference: action.payload
      };
    case 'setNbPersonne':
      return {
        ...state,
        nombre_personnes: action.payload
      };
    case 'setLieu':
      return {
        ...state,
        lieu: action.payload
      };
    case 'setGites':
      return {
        ...state,
        gite_selected: action.payload
      };
    case 'setSujet':
      return {
        ...state,
        mail_sujet: action.payload
      };
    case 'setMessage':
      return {
        ...state,
        mail_message: action.payload
      };
    case 'setAdresse':
      return {
        ...state,
        client_adresse: action.payload
      };
    case 'setInitial':
      return {
        ...state,
        gite_initial: action.payload
      };
    case 'setTelephone':
      return {
        ...state,
        client_telephone: action.payload
      };
    case 'calcul':
      const nbPersonne = state.nombre_personnes
      const nbNuit = state.nombre_nuits
      const personneSup = nbPersonne > 4 ? nbPersonne - 4 : 0
      let montant_sejour = Math.round((action.tarif * 1 * nbNuit) + (personneSup * 10 * nbNuit))
      if (montant_sejour === 0) {
        montant_sejour = action.tarif
      };
      return {
        ...state,
        montant_sejour: montant_sejour
      };
    default:
      return state;
  }
}


export default contactForm;
