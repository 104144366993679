import { Component } from 'react';
import Player from './Player';
import { connect } from 'react-redux';
import 'react-modal-video/css/modal-video.min.css';
import ShortGites from './ShortGites';
import { Helmet } from "react-helmet";

class PageAccueil extends Component {
  render() {
    const carresInformations = [
      {
        titre: '350 mètres',
        description: "C'est la distance entre le débarcadère et les gîtes",
      },
      {
        titre: '5 minutes',
        description: "A pied de toutes commodités. Nul besoin de voiture.",
      },
      {
        titre: '500 mètres',
        description: "C'est la distance entre les gites et la plage.",
      },
      {
        titre: '30 mètres',
        description: "C'est la distance entre les gîtes et la mer.",
      },
    ]

    return (
      <div className="pb-4">
        <Helmet>
          <title>Gites à partir de 50€ - Vacansosoleil</title>
        </Helmet>
        <Player loading="lazy" open={this.props.popup.open} />
        <div className="container-fluid bg-primary py-5 mb-2 hero-header">
          <div className="container py-5">
            <div className="row justify-content-start">
              <div className="col-lg-8 text-center text-lg-start">
                <h1 className="font-secondary lumiere text-primary mb-4">Location de gîtes à Marie-Galante</h1>
                <h2 className="display-1 text-uppercase text-white mb-4">Face ou très proche de la mer</h2>
                <h2 className="text-uppercase text-white">A grand-Bourg de Marie-Galante</h2>
                <div className="d-flex align-items-center justify-content-center justify-content-lg-start pt-5">

                  <button type="button" className="btn-play" onClick={() => this.props.changeStatus()} >
                    <span></span>
                  </button>
                  <h5 className="lumiere font-weight-normal text-white m-0 ms-4 d-none d-sm-block">Lire la vidéo</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container p-1 pb-4">
            <div className=" section-title position-relative text-center mx-auto mb-5 pb-3" style={{ maxWidth: '600px' }}>
              <h2 className="text-primary font-secondary">Découvez nos gîtes</h2>
              <h3 className="display-4 text-uppercase lumiere">A Marie-Galante</h3>
            </div>
            <div className="row gx-5">
              <div className="col-lg-5 mb-5 mb-lg-0 pb-3" style={{ minHeight: '300px' }}>
                <div className="row position-relative h-100">
                  <picture>
                    <source srcset="/img/carte.avif" type="image/avif" />
                    <img className="align-items-center bg-img-home-maps h-100 w-100 shadow p-3 mb-5 rounded" alt="Carte de la localisation des gîtes à Marie-Galante" src='/img/carte.webp'></img>
                  </picture>
                </div>
              </div>
              <div className="col-lg-6 pb-1 fs-5 text">
                <h4 className="mb-4">Bienvenue à Marie-Galante où nous vous hébergeons.</h4>
                <div className="row g-5 p-0 mb-5 bg-body text-center  rounded align-items-center justify-content-center" >
                  {carresInformations.map(carre => {
                    return (
                      <div key={carre.titre} className="col-sm-6">
                        <div className="d-flex container align-items-center justify-content-center bg-primary border-inner mb-4"
                          style={{ 'width': '90px', 'height': '90px' }}>
                          <picture>
                            <source srcset="/img/verifier.avif" type="image/avif" />
                            <img src="/img/verifier.png" alt="Les avantages de la location" className="text-white" style={{ 'width': '60px', 'height': '60px' }}></img>
                          </picture>
                          {/* <i className="fa fa-check text-white"></i> */}
                        </div>
                        <h4 className="text-uppercase" data-toggle="counter-up">{carre.titre}</h4>
                        <p className="mb-0">{carre.description}</p>
                      </div>)
                  })}
                </div>
                {this.props.home.map(paragraphe => {
                  return <p className="mb-5">{paragraphe}</p>
                })}
              </div>
            </div>
          </div>
        </div>
        <ShortGites />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    popup: state.popup,
    home: state.home,
    gites: state.gites
  }
}

const mapDispatchToProps = dispatch => {
  return {
    changeStatus: () => {
      dispatch({ type: 'view' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageAccueil);
