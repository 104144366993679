import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function BasicModal(props) {
  const getContract = window.location.origin + '/contrat'
  const open = useSelector((state) => state.mail.open);
  const message = useSelector((state) => state.mail.message);
  const status = useSelector((state) => state.mail.status);
  const redis_key = useSelector((state) => state.mail.redis_key);
  const error = useSelector((state) => state.mail.error);
  const handleClose = () => props.updateFormDate('mailValidator', false);
  const useClass = status === "KO" ? "float-right justify-content-end btn btn-lg btn-outline-danger" : "float-right justify-content-end btn btn-lg btn-outline-success"

  const msg = () => {
    if (status === "OK") {
      return <>
        Nous avons généré un contrat. Les dates de disponibilité ainsi que le tarif seront examinés par le propriétaire. <br /><br />
        Vous pouvez cependant dès à présent nous le renvoyer complété à <b>vacansosoleil@wanadoo.fr</b>.<br /><br />

        <p style={{ color: "#FF0000" }}><b>Ce lien expire dans 7 jours.</b></p>
        <b>Lien de téléchargement: <a href={getContract + "?key=" + redis_key} download>Contrat</a></b>
        <br /><br />
      </>
    } else if (status === null) {
      return <>
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </>
    }
  }

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style} className="col-md-12">
          <Typography id="modal-modal-title" variant="h6" component="h2" className={useClass}>
            Validation d'envoie
          </Typography>
          <Typography className='display-2' id="modal-modal-description" sx={{ mt: 2 }}>
            <b>{message !== undefined ? message : <div class="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>}</b> <br /><br /> {error}
            {msg()}
          </Typography>
          <div className='col-md-6 justify-content-end'>
            <button type="button" onClick={handleClose} className={useClass + " justify-content-end"} >OK</button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    popup: state.mail,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateFormDate: (type, value) => {
      dispatch({ type: type, payload: value })
    },
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(BasicModal);