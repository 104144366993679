
import gitesStruct from '../../assets/gitesStruct.json';

const initialState = {
  gites: gitesStruct,
//   {
//     to: {
//       id: "1",
//       initial: "to",
//       maxclient: 5,
//       url_folder: 'la-topaze-d-or',
//       nom: "La Topaze d'Or",
//       image: '/img/gites/to/1.jpg',
//       image_min: 'img/gites/to/min_1.jpg',
//       tarif: 50,
//       tarifSejour: 50,
//       maps: {
//         center: {
//           lat: 15.880555363318539,
//           lng: -61.31361979447724,
//         },
//         zoom: 18,
//       },
//       short_desc: "Gîte a quelque mètre de la mer.",
//       adresse: `69, Boulevard maritime - 97112 Grand-Bourg - Marie-Galante`,
//       description: {
//         premiereLigne: `La Topaze d'Or, cet hébergement peut recevoir jusqu'à 4 personnes, comprend :`,
//         listDescription: [
//           `Une chambre séparée avec un lit pour 2 personnes avec moustiquaire`,
//           `Un hébergement climatisé`,
//           `Une salle d'eau`,
//           `Une pièce principale avec buffet`,
//           `Une table et 4 chaises`,
//           `Un clic-clac`,
//           `Un téléviseur`,
//           `Un ventilateur`,
//           `Un four à micro-ondes`,
//           `Une cafetière`,
//           `Un mixer`,
//           `et des ustensiles de cuisine`,
//         ]
//       },
//       important:
//         [
//           { type: "climatisation", message: "" },
//           { type: "lave_linge", message: "" },
//           { type: "lit_double", message: "" },
//           { type: "parking", message: "" },
//           { type: "personne", message: "Jusqu'à 5 personnes" },
//           { type: "surface", message: "" },
//           { type: "tv", message: "" },
//           { type: "wifi", message: "" },
//         ]
//     },
//     js: {
//       id: "2",
//       initial: "js",
//       maxclient: 5,
//       url_folder: "le-joyeux-saphir",
//       nom: "Le Joyeux Saphir",
//       image: '/img/gites/js/1.jpg',
//       image_min: 'img/gites/js/min_1.jpg',
//       tarif: 50,
//       tarifSejour: 50,
//       maps: {
//         center: {
//           lat: 15.880589546200783,
//           lng: -61.31359833680446,
//         },
//         zoom: 18,
//       },
//       short_desc: "Gîte a quelque mètre de la mer.",
//       adresse: `69, Boulevard maritime - 97112 Grand-Bourg - Marie-Galante`,
//       description: {
//         premiereLigne: `Le Joyex Saphir, cet hébergement peut recevoir jusqu'à 4 personnes, comprend :`,
//         listDescription: [
//           `Une chambre séparée avec un lit pour 2 personnes avec moustiquaire`,
//           `Un hébergement climatisé`,
//           `Une salle d'eau`,
//           `Une pièce principale avec buffet`,
//           `Une table et 4 chaises`,
//           `Un clic-clac`,
//           `Un téléviseur`, `Un ventilateur`, `Un four à micro-ondes`, `Une cafetière`, `Un mixer`, `Et des ustensiles de cuisine`,
//         ]
//       },
//       important:
//         [
//           { type: "climatisation", message: "" },
//           { type: "lave_linge", message: "" },
//           { type: "lit_double", message: "" },
//           { type: "parking", message: "" },
//           { type: "personne", message: "Jusqu'à 5 personnes" },
//           { type: "surface", message: "" },
//           { type: "tv", message: "" },
//           { type: "wifi", message: "" },
//         ]
//     },
//     ra: {
//       id: "3",
//       initial: "ra",
//       nom: "Le Rubis Ardent",
//       maxclient: 5,
//       url_folder: "le-rubis-ardent",
//       image: '/img/gites/ra/1.jpg',
//       image_min: 'img/gites/ra/min_1.jpg',
//       tarif: 60,
//       tarifSejour: 60,
//       maps: {
//         center: {
//           lat: 15.880508281225946,
//           lng: -61.31364393435913,
//         },
//         zoom: 18,
//       },
//       short_desc: "Gîte avec vue direct sur la mer.",
//       adresse: `69, Boulevard maritime - 97112 Grand-Bourg - Marie-Galante`,
//       description: {
//         premiereLigne: `Le Rubis Ardent, cet hébergement peut recevoir jusqu'à 5 personnes, comprend :`,
//         listDescription: [
//           `1 chambre climatisée avec un lit 2 places et moustiquaire et 1 lit d'une place`,
//           `1 placard`,
//           `1 salle d'eau`,
//           `1 coin cuisinette avec four micro-onde`,
//           `1 salle principale avec buffet`, `table`, `5 chaises`, `clic-clac`, `télévision`, `ventilateur.`,
//         ]
//       },
//       important:
//         [
//           { type: "climatisation", message: "" },
//           { type: "lave_linge", message: "" },
//           { type: "lit_double", message: "1 double et 2 petits" },
//           { type: "parking", message: "" },
//           { type: "personne", message: "Jusqu'à 5 personnes" },
//           { type: "surface", message: "50 mètres²" },
//           { type: "tv", message: "" },
//           { type: "vue_mer", message: "" },
//           { type: "wifi", message: "" },
//         ]
//     },
//     be: {
//       id: "4",
//       initial: "be",
//       nom: "La Belle Emeraude",
//       maxclient: 6,
//       url_folder: "la-belle-emeraude",
//       image: '/img/gites/be/1.jpg',
//       image_min: 'img/gites/be/min_1.jpg',
//       tarif: 80,
//       tarifSejour: 80,
//       maps: {
//         center: {
//           lat: 15.880480004225806,
//           lng: -61.313555875456885,
//         },
//         zoom: 18,
//       },
//       short_desc: "Gîte avec vue direct sur la mer.",
//       adresse: `69, Boulevard maritime - 97112 Grand-Bourg - Marie-Galante`,
//       description: {
//         premiereLigne: `La Belle Émeraude, cet hébergement peut recevoir jusqu'à 6 personnes, comprend :`,
//         listDescription: [
//           `1 chambre climatisée pour un couple, équipée d'un lit-pont, d'une moustiquaire et donnant sur la mer`,
//           `1 chambre climatisée avec deux lits d'une personne, armoire et moustiquaire`,
//           `1 cuisine bien équipée comprenant une gazinière, un évier à deux bacs, un réfrigérateur, un buffet, un meuble bas, un micro-ondes, une table carrelée, 6 tabourets. Elle est équipée de vaisselle (verres, assiettes plates et creuses, couverts, casseroles, autocuiseur, cafetière, bol mixer, passoire, poêle, etc…)`,
//           `1 salle d'eau avec un lavabo, douche, armoire de toilette, W.C., chauffe-eau et lave-linge (5 kgs),`,
//           `1 salon/salle à manger avec deux canapés convertibles, une table basse, un meuble avec la télévision, un buffet, quatre chaises, un ventilateur`,
//           `1 véranda avec une table et quatre chaises sous laquelle il fait bon prendre ses repas ou faire une petite sieste,`,
//           `1 fer et une table à repasser`,
//           `deux transats et quelques jeux.`,
//         ]
//       },
//       important:
//         [
//           { type: "climatisation", message: "" },
//           { type: "lave_linge", message: "Oui, individuel" },
//           { type: "lit_double", message: "1 double et 2 petits" },
//           { type: "parking", message: "" },
//           { type: "personne", message: "Jusqu'à 6 personnes" },
//           { type: "surface", message: "80 mètres²" },
//           { type: "tv", message: "" },
//           { type: "vue_mer", message: "" },
//           { type: "wifi", message: "" },
//         ]
//     },
//     jp: {
//       id: "5",
//       initial: "jp",
//       nom: "Le Jupiter",
//       maxclient: 8,
//       url_folder: "le-jupiter",
//       image: '/img/gites/jp/1.jpg',
//       image_min: 'img/gites/jp/min_1.jpg',
//       tarif: 90,
//       tarifSejour: 90,
//       maps: {
//         center: {
//           lat: 15.881454354015663,
//           lng: -61.31242511602227,
//         },
//         zoom: 16,
//       },
//       short_desc: "Grand gîte allant jusqu'à 8 personnes.",
//       adresse: `Rue Emilie Bambuck - 97112 Grand-Bourg - Marie-Galante`,
//       description: {
//         premiereLigne: `La Belle Émeraude, cet hébergement peut recevoir jusqu'à 6 personnes, comprend :`,
//         listDescription: [
//           `1 chambre climatisée pour un couple, équipée d'un lit-pont, d'une moustiquaire et donnant sur la mer`,
//           `1 chambre climatisée avec deux lits d'une personne, armoire et moustiquaire`,
//           `1 cuisine bien équipée comprenant une gazinière, un évier à deux bacs, un réfrigérateur, un buffet, un meuble bas, un micro-ondes, une table carrelée, 6 tabourets. Elle est équipée de vaisselle (verres, assiettes plates et creuses, couverts, casseroles, autocuiseur, cafetière, bol mixer, passoire, poêle, etc…)`,
//           `1 salle d'eau avec un lavabo, douche, armoire de toilette, W.C., chauffe-eau et lave-linge (5 kgs),`,
//           `1 salon/salle à manger avec deux canapés convertibles, une table basse, un meuble avec la télévision, un buffet, quatre chaises, un ventilateur`,
//           `1 véranda avec une table et quatre chaises sous laquelle il fait bon prendre ses repas ou faire une petite sieste,`,
//           `Sont également mis à votre disposition un fer et une table à repasser, deux transats et quelques jeux.`,
//         ]
//       },
//       important:
//         [
//           { type: "climatisation", message: "" },
//           { type: "lave_linge", message: "Lave linge" },
//           { type: "lit_double", message: "1 grand, 2x2 petits" },
//           { type: "parking", message: "" },
//           { type: "personne", message: "jusqu'à 8 personnes" },
//           { type: "surface", message: "83 mètres²" },
//           { type: "tv", message: "" },
//         ]
//     }
//   },
  important: {
    vue_mer: {
      icon: "/img/icons/vue_mer.png",
      message: "Vue sur mer"
    },
    climatisation: {
      icon: "/img/icons/climatisation.png",
      message: "Climatisé"
    },
    wifi: {
      icon: "/img/icons/wifi.png",
      message: "Accès au WIFI"
    },
    parking: {
      icon: "/img/icons/parking.png",
      message: "Possibité de se garer"
    },
    lave_linge: {
      icon: "/img/icons/lave_linge.png",
      message: "Lave linge partagé"
    },
    lit_double: {
      icon: "/img/icons/lit_double.png",
      message: "1 lit double",
    },
    tv: {
      icon: "/img/icons/tv.png",
      message: "Une TV",
    },
    personne: {
      icon: "/img/icons/personne.png",
      message: "Jusqu'à 5 personnes",
    },
    surface: {
      icon: "/img/icons/surface.png",
      message: "40 mètre²",
    }
  }
};

const nosGites = (state = initialState, action) => {
  switch (action.type) {
    case 'setSejourTarif':
      return {
        ...state,
        gites: {
          ...state.gites,
            [action.initial]: {
              ...state.gites[action.initial],
              tarifSejour: action.payload
            }
          }
      };
    // return state;
    default:
      return state;
  }
}

export default nosGites;
