const initialState = {
  open: false,
  titre: "",
  messages: "",
  status: "",
  error: "",
  redis_key: "",
};

const mailValidator = (state = initialState, action) => {
  switch (action.type) {
    case 'setValidatorMessage':
      return {
        ...state,
        messages: action.payload
      };
    case 'setValidatorTitre':
      return {
        ...state,
        titre: action.payload
      };
    case 'mailValidator':
      return {
        ...state,
        open: action.payload
      };
    case 'returnValue':
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.error,
        status: action.payload.status,
        redis_key: action.payload.redis_key,
      };
    default:
      return state;
  }
};

export default mailValidator;
