import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const location = useLocation();
  const ref = useRef();
  const pathName = location.pathname;

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [location, ref, pathName]);

  return
}


export default ScrollToTop;